import { debugLog } from './debug';
import { hasParentWithClass, queryEl, SERVER_RENDERED_IFRAME_SELECTOR, UNUSED_PLACEHOLDER_SELECTOR } from './dom';
import { objectValues } from './object';
const DATA_ATTR_PREFIX = 'hsv';
const CONVERSION_ASSET_ATTR_PREFIX = 'ca';
const NUMERIC_OPTIONS = ['id', 'width', 'height'];
const ATTR_NAME_TRANSFORMS = {
  fullWidth: 'full_width'
}; // selectors which we'll ignore placeholders inside of

const IGNORE_PLACEHOLDERS_WITHIN_SELECTORS = ['.slick-slide.slick-cloned', // HS Image gallery widget uses slick JS lib which immediately clones slide content
'.swiper-slide-duplicate' // from https://www.learners.ai/about-us
];

const uncapitalize = str => `${str[0].toLowerCase()}${str.slice(1)}`;

function parsePlaceholderAttrs(el) {
  let imgEl = el.querySelector('img[data-hsv-id]');

  if (!imgEl) {
    imgEl = el.querySelector('iframe[data-hsv-src]');
  }

  if (!imgEl) {
    return null;
  }

  const dataset = Object.assign({}, imgEl.dataset);
  const options = {
    embedId: el.dataset.hsvEmbedId
  };
  Object.keys(dataset).forEach(attrName => {
    let value = dataset[attrName];
    attrName = uncapitalize(attrName.replace(DATA_ATTR_PREFIX, ''));

    if (attrName in ATTR_NAME_TRANSFORMS) {
      attrName = ATTR_NAME_TRANSFORMS[attrName];
    }

    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    } else if (NUMERIC_OPTIONS.includes(attrName)) {
      if (!attrName.includes('%') && !Number.isNaN(Number(value))) {
        value = Number(value);
      }
    }

    if (attrName.startsWith('ca')) {
      attrName = uncapitalize(attrName.replace(CONVERSION_ASSET_ATTR_PREFIX, ''));
      options.conversionAsset = options.conversionAsset || {};

      if (attrName === 'overlayDelaySeconds') {
        value = Number(value);
      }

      options.conversionAsset[attrName] = value;
    } else {
      options[attrName] = value;
    }
  });
  return options;
}

export function isValidPlaceholder(el) {
  return !IGNORE_PLACEHOLDERS_WITHIN_SELECTORS.some(className => hasParentWithClass(el, className));
}
export function findPlaceholderImages(parentEl = document.body) {
  const wrapperEls = queryEl(parentEl, UNUSED_PLACEHOLDER_SELECTOR).filter(el => el.querySelector('img[data-hsv-id]'));
  const validWrapperEls = wrapperEls.filter(isValidPlaceholder);

  if (validWrapperEls.length !== wrapperEls.length) {
    debugLog(`Found only ${validWrapperEls.length} valid placeholders out of ${wrapperEls.length} total.`, wrapperEls);
  }

  const placeholders = validWrapperEls.map(parsePlaceholderAttrs);
  const uniquePlaceholders = {};
  validWrapperEls.forEach((el, i) => {
    const options = placeholders[i];

    if (options && options.embedId) {
      uniquePlaceholders[options.embedId] = {
        id: options.embedId,
        el,
        options
      };
    }
  });

  if (Object.keys(uniquePlaceholders).length !== placeholders.length) {
    debugLog(`Found only ${Object.keys(uniquePlaceholders).length} unique embedIds for ${placeholders.length} placeholders.`, validWrapperEls);
  }

  return objectValues(uniquePlaceholders);
}
export function getServerRenderedPlayers(parentEl = document.body) {
  const wrapperEls = queryEl(parentEl, UNUSED_PLACEHOLDER_SELECTOR).filter(el => el.querySelector(SERVER_RENDERED_IFRAME_SELECTOR));
  return wrapperEls.map(el => ({
    el,
    options: parsePlaceholderAttrs(el)
  }));
}