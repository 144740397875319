export const EMBED_PLACEHOLDER_SELECTOR = '[data-hsv-embed-id]:not([data-hsv-status])';
export const DEFAULT_ASPECT_RATIO = 9 / 16;
export const PLAYER_EVENT_TYPE = 'HS:PlayerMessage';
export let MessageTypes;

(function (MessageTypes) {
  MessageTypes["SET_UTK"] = "SET_UTK";
  MessageTypes["SET_PAGE_META"] = "SET_PAGE_META";
  MessageTypes["SET_CURRENT_TIME"] = "SET_CURRENT_TIME";
  MessageTypes["SET_PLAYBACK_RATE"] = "SET_PLAYBACK_RATE";
  MessageTypes["UPDATE_DISPLAY_OPTIONS"] = "UPDATE_DISPLAY_OPTIONS";
  MessageTypes["SET_PLAYER_STATUS"] = "SET_PLAYER_STATUS";
  MessageTypes["CONFIRM_VISIBLE"] = "CONFIRM_VISIBLE";
  MessageTypes["CTA_SKIPPED"] = "CTA_SKIPPED";
  MessageTypes["CTA_CLICKED"] = "CTA_CLICKED";
  MessageTypes["FORM_SKIPPED"] = "FORM_SKIPPED";
  MessageTypes["FORM_SUBMITTED"] = "FORM_SUBMITTED";
  MessageTypes["VIDEO_LOADED"] = "VIDEO_LOADED";
  MessageTypes["PLAYER_LOADED"] = "PLAYER_LOADED";
  MessageTypes["PLAYER_READY"] = "PLAYER_READY";
  MessageTypes["PLAYER_PLAY"] = "PLAYER_PLAY";
  MessageTypes["PLAYER_PAUSE"] = "PLAYER_PAUSE";
  MessageTypes["PLAYER_ENDED"] = "PLAYER_ENDED";
  MessageTypes["PLAYER_PROGRESS"] = "PLAYER_PROGRESS";
  MessageTypes["PLAYER_SHOW_OVERLAY"] = "PLAYER_SHOW_OVERLAY";
  MessageTypes["PLAYER_ORPHANED"] = "PLAYER_ORPHANED";
  MessageTypes["PLAYER_ERROR"] = "PLAYER_ERROR";
  MessageTypes["PLAYER_ANALYTICS_EVENTS"] = "PLAYER_ANALYTICS_EVENTS";
  MessageTypes["PLAYER_SECONDS_VIEWED"] = "PLAYER_SECONDS_VIEWED";
  MessageTypes["TRACKED_PLAY"] = "TRACKED_PLAY";
})(MessageTypes || (MessageTypes = {}));

export const PLAYER_IFRAME_PASSTHRU_PARAMS = ['region', 'posterUrl', 'playButtonColor', 'play_button_color', 'playButtonShape', 'play_button_shape', 'preview', 'richTextPreview', 'playerType', 'autoplay', 'autoPlay', 'loop', 'muted', 'hiddenControls', 'hidden_controls', 'hostApp', 'renderContext', 'parentUrl', 'maxHeight', 'objectFit', 'playbackRate'];
export const CONFIRM_PLAYER_RENDERED_DELAY_MS = 1000; // the /share/hubspotvideo/{videoId} path linked to by email video module uses 0 for its pageId context

export const EMAIL_SHARE_PAGE_PATH = '/share/hubspotvideo/';
export const EMAIL_SHARE_PAGE_ID = 0;