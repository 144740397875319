import createLoader from './loader';
import { debugLog } from './utils/debug';
let hsVideoApi = window.hsVideoApi;

if (!hsVideoApi) {
  window.hsVideoApi = hsVideoApi = createLoader();
} else {
  debugLog('hsVideoApi found on page, not calling createLoader');
}

export const renderPlayer = hsVideoApi.renderPlayer;
export const updatePlayer = hsVideoApi.updatePlayer;