import { isLocal, tryLocalStorageGet } from './env';
export const LOCALSTORAGE_DEBUG_KEY = 'hs_video_debug';
export function isDebugEnabled() {
  if (isLocal() || window.hsVideoDebug || window.location.search.includes('debug=true')) {
    return true;
  }

  return tryLocalStorageGet(LOCALSTORAGE_DEBUG_KEY) === 'true';
}
export function debugLog(...args) {
  if (isDebugEnabled()) {
    const first = args.shift();
    console.log.apply(console, [`[hs-video] ${first}`, ...args]);
  }
}