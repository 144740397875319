import { MessageTypes } from '../constants';
import { getPortalId, isCmsEditorPreview } from './cms';
import { debugLog } from './debug';
import { getEmbedPlaceholderEl } from './dom';
import { reportMessage } from './errors';
import { getEmbedIframeDomain } from './env';
import { PlayerStatus, PlayerTypes } from '../types';
import { buildIframeQueryParams, getEmbedIframeUrl } from './url';
import EmbedPlayer from './EmbedPlayer';
import { objectValues } from './object';
export function isPlayerMessage(event, expectedSource) {
  if (event && event.data && typeof event.data === 'object' && Object.keys(MessageTypes).includes(event.data.type)) {
    if (expectedSource && event.origin && event.origin !== expectedSource) {
      debugLog('isPlayerMessage saw unexpected origin', event);
    }

    return true;
  }

  return false;
}
export function renderEmbeddedPlayer(id, options, {
  placeholderEl
} = {}) {
  if (!placeholderEl && options.embedId) {
    placeholderEl = getEmbedPlaceholderEl(id, true);

    if (placeholderEl) {
      options.renderContext = 'embed-id-selector';
    } else {
      debugLog(`Failed to find for embedId: ${options.embedId} for player: ${id}`);
    }
  }

  const portalId = options.portalId || getPortalId();

  if (!portalId || !options.id) {
    reportMessage('Required param `portalId` or video `id` missing');
  }

  if (isCmsEditorPreview()) {
    options.playerType = PlayerTypes.EDITOR_PREVIEW;
    options.renderContext = 'editor-preview';
  }

  const embedPlayer = new EmbedPlayer(id, portalId, options, placeholderEl);
  embedPlayer.injectPlayer();
  return embedPlayer;
}
export function updatePlayerOptions(player, options) {
  const portalId = options.portalId || getPortalId();
  const iframeParams = buildIframeQueryParams(options);
  const iframeSrc = getEmbedIframeUrl(portalId, options.id, iframeParams, player.id, options);

  if (options.conversionAsset && player.options.conversionAsset && options.conversionAsset.id !== player.options.conversionAsset.id) {
    const overlayEl = player.el.querySelector('.hsv-overlay');

    if (overlayEl) {
      overlayEl.remove();
    }
  }

  debugLog(`Updating src of existing iframe for player ${player.id}`, options);
  player.options = options;
  player.iframeEl.src = iframeSrc;

  if (options.altText) {
    player.iframeEl.title = options.altText;
  }

  return player;
}
export function postMessageToPlayers(players, type, payload, queueIfNotReady = true) {
  objectValues(players).forEach(player => {
    player.postMessageToPlayer(type, payload, queueIfNotReady);
  });
}
export function setupPlayerMessageListener(players) {
  window.addEventListener('message', e => {
    if (isPlayerMessage(e, getEmbedIframeDomain())) {
      const {
        data
      } = e;
      const {
        type,
        embedId,
        payload = {}
      } = data;
      const player = players[embedId];

      if (!player) {
        console.warn(`Could not find embedId ${embedId}`, data);
        return;
      }

      player.onMessageReceived(type, payload);

      if (type === MessageTypes.PLAYER_PLAY && !(player.options.autoplay && payload.isInitial)) {
        const otherPlayingPlayers = objectValues(players).filter(p => {
          return p !== player && p.status === PlayerStatus.PLAYING;
        });

        if (otherPlayingPlayers.length) {
          debugLog(`Pausing ${otherPlayingPlayers.length} other currently playing players`, otherPlayingPlayers);
        }

        otherPlayingPlayers.forEach(p => p.triggerPause());
      }

      if (type === MessageTypes.PLAYER_ANALYTICS_EVENTS) {
        const payloadObj = JSON.parse(payload);

        if (payloadObj.events && payloadObj.events.length && window._hsq) {
          payloadObj.events.forEach(events => window._hsq.push(events));
        }
      }
    }
  });
}